<template>
  <div class="ElectricityMonitoring">
    <DeviceMenu />
    <!-- 筛选项 -->
    <div class="wrap">
      <el-form :inline="true">
        <el-form-item label="项目名称">
          <el-select
            size="small"
            v-model="currentProject"
            placeholder="请选择项目"
          >
            <el-option
              v-for="item in projectList"
              :key="item.id"
              :label="item.proName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备名称">
          <el-input
            size="small"
            v-model="deviceName"
            placeholder="请输入设备名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="设备ID">
          <el-input
            size="small"
            v-model="serialNumber"
            placeholder="请输入设备ID"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="small" icon="el-icon-search" @click="getDeviceList"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <!-- 功能按钮 -->
    <div class="wrap-blank">
      <el-button
        size="small"
        plain
        type="primary"
        icon="el-icon-plus"
        @click="dialogEditVisible = true"
        >添加</el-button
      >
    </div>

    <!-- 数据列表 -->
    <div class="wrap">
      <el-table
        :data="deviceList"
        style="width: 100%"
        @row-click="setCurrentDevice"
      >
        <!-- <el-table-column type="index" width="50"> </el-table-column> -->
        <el-table-column prop="serialNumber" label="设备编号">
        </el-table-column>
        <el-table-column prop="deviceName" label="设备名称"> </el-table-column>
        <el-table-column prop="pkey" label="pKey"> </el-table-column>
        <el-table-column prop="dsn" label="dSn"> </el-table-column>
        <el-table-column prop="installDate" label="安装日期"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              icon="el-icon-edit"
              @click.stop="editDevice(scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click.stop="delDevice(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        layout="total, sizes, prev, pager, next"
        :page-size.sync="pageSize"
        :current-page.sync="pageNum"
        :page-sizes="[2, 5, 10, 20, 50]"
        :total="totals"
        @current-change="getDeviceList"
        @size-change="getDeviceList"
      >
      </el-pagination>
    </div>

    <!-- 设备的详细数据 -->
    <el-dialog
      :title="currentDeviceName"
      custom-class="device-record"
      :visible.sync="dialogTableVisible"
      @close="closeDeviceDialog"
    >
      <div class="dialog-wrap">
        <el-form :inline="true">
          <el-form-item label="数据类型">
            <span
              class="filterType"
              @click="DeviceDataType = 1"
              :class="[DeviceDataType == 1 ? 'filterTypeActive' : '']"
              >监测数据</span
            >
            <span
              class="filterType"
              @click="DeviceDataType = 2"
              :class="[DeviceDataType == 2 ? 'filterTypeActive' : '']"
              >报警数据</span
            >
          </el-form-item>
        </el-form>
        <el-form :inline="true">
          <el-form-item label="搜索日期">
            <el-date-picker
              size="small"
              v-model="DeviceDateZone"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button size="small" @click="searchDeviceData">查询</el-button>
          </el-form-item>
        </el-form>
      </div>

      <el-table :data="DeviceRecord" style="width: 100%">
        <el-table-column
          prop="uploadTime"
          label="时间"
          width="200"
          fixed
        ></el-table-column>
        <el-table-column
          v-if="DeviceDataType == 2"
          prop="alarmTest"
          label="报警类型"
          width="200"
        ></el-table-column>
        <el-table-column
          v-if="DeviceDataType == 2"
          prop="alarmDataValue"
          label="报警内容"
        ></el-table-column>
        <el-table-column
          v-if="DeviceDataType == 1"
          prop="temp1"
          label="线缆温度1 ℃"
          width="120"
        ></el-table-column>
        <el-table-column
          v-if="DeviceDataType == 1"
          prop="temp2"
          label="线缆温度2 ℃"
          width="120"
        ></el-table-column>
        <el-table-column
          v-if="DeviceDataType == 1"
          prop="temp3"
          label="线缆温度3 ℃"
          width="120"
        ></el-table-column>
        <el-table-column
          v-if="DeviceDataType == 1"
          prop="temp4"
          label="线缆温度4 ℃"
          width="120"
        ></el-table-column>
        <el-table-column
          v-if="DeviceDataType == 1"
          prop="phaseVolta"
          label="A相电压 v"
          width="120"
        ></el-table-column>
        <el-table-column
          v-if="DeviceDataType == 1"
          prop="phaseVoltb"
          label="B项电压 v"
          width="120"
        ></el-table-column>
        <el-table-column
          v-if="DeviceDataType == 1"
          prop="phaseVoltc"
          label="C项电压 v"
          width="120"
        ></el-table-column>
        <el-table-column
          v-if="DeviceDataType == 1"
          prop="phaseVoltavg"
          label="平均电压 v"
          width="120"
        ></el-table-column>
        <el-table-column
          v-if="DeviceDataType == 1"
          prop="eleca"
          label="A项电流 A"
          width="120"
        ></el-table-column>
        <el-table-column
          v-if="DeviceDataType == 1"
          prop="elecb"
          label="B项电流 A"
          width="120"
        ></el-table-column>
        <el-table-column
          v-if="DeviceDataType == 1"
          prop="elecc"
          label="C项电流 A"
          width="120"
        ></el-table-column>
        <el-table-column
          v-if="DeviceDataType == 1"
          prop="elecAvg"
          label="平均电流 A"
          width="120"
        ></el-table-column>
      </el-table>

      <el-pagination
        layout="total, sizes, prev, pager, next"
        :page-size.sync="DevicePageSize"
        :current-page.sync="DevicePageNum"
        :page-sizes="[2, 5, 10, 20, 50]"
        :total="DeviceTotals"
        @current-change="searchDeviceData"
        @size-change="searchDeviceData"
      >
      </el-pagination>
    </el-dialog>

    <!-- 添加/编辑设备 -->
    <el-dialog
      :title="currentDeviceName ? currentDeviceName : '添加新设备'"
      custom-class="device-record"
      :visible.sync="dialogEditVisible"
      @close="closeEditDialog"
    >
      <el-descriptions title="设备详细信息" border :column="2">
        <template slot="extra">
          <el-button
            type="primary"
            size="small"
            plain
            icon="el-icon-check"
            @click="saveEditData"
            >保存</el-button
          >
        </template>
        <el-descriptions-item
          label="设备名称"
          labelClassName="my-label"
          contentClassName="my-content"
        >
          <el-input
            size="small"
            clearable
            v-model="editData.deviceName"
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item
          label="设备编号"
          labelClassName="my-label"
          contentClassName="my-content"
        >
          <el-input
            size="small"
            clearable
            v-model="editData.serialNumber"
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item
          label="pKey"
          labelClassName="my-label"
          contentClassName="my-content"
        >
          <el-input size="small" clearable v-model="editData.pkey"></el-input>
        </el-descriptions-item>
        <el-descriptions-item
          label="dSn"
          labelClassName="my-label"
          contentClassName="my-content"
        >
          <el-input size="small" clearable v-model="editData.dsn"></el-input>
        </el-descriptions-item>
        <el-descriptions-item
          label="规格型号"
          labelClassName="my-label"
          contentClassName="my-content"
        >
          <el-input
            size="small"
            clearable
            v-model="editData.deviceModel"
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item
          label="备案日期"
          labelClassName="my-label"
          contentClassName="my-content"
        >
          <el-date-picker
            v-model="editData.recordData"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            align="right"
            type="date"
            placeholder="选择日期"
          ></el-date-picker>
        </el-descriptions-item>
        <el-descriptions-item
          label="备案编号"
          labelClassName="my-label"
          contentClassName="my-content"
        >
          <el-input
            size="small"
            clearable
            v-model="editData.recordNo"
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item
          label="出厂日期"
          labelClassName="my-label"
          contentClassName="my-content"
        >
          <el-date-picker
            v-model="editData.mintedDate"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            align="right"
            type="date"
            placeholder="选择日期"
          ></el-date-picker>
        </el-descriptions-item>
        <el-descriptions-item
          label="出厂编号"
          labelClassName="my-label"
          contentClassName="my-content"
        >
          <el-input
            size="small"
            clearable
            v-model="editData.mintedNumber"
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item
          label="制造厂家"
          labelClassName="my-label"
          contentClassName="my-content"
        >
          <el-input
            size="small"
            clearable
            v-model="editData.manufacturer"
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item
          label="设备产权单位"
          labelClassName="my-label"
          contentClassName="my-content"
        >
          <el-input
            size="small"
            clearable
            v-model="editData.propertyUnit"
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item
          label="制造许可证号"
          labelClassName="my-label"
          contentClassName="my-content"
        >
          <el-input
            size="small"
            clearable
            v-model="editData.licenseNo"
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item
          label="联系电话"
          labelClassName="my-label"
          contentClassName="my-content"
        >
          <el-input
            size="small"
            clearable
            v-model="editData.contactNumber"
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item
          label="安装日期"
          labelClassName="my-label"
          contentClassName="my-content"
        >
          <el-date-picker
            v-model="editData.installDate"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            align="right"
            type="date"
            placeholder="选择日期"
          ></el-date-picker>
        </el-descriptions-item>
        <el-descriptions-item
          label="备注"
          labelClassName="my-label"
          contentClassName="my-content"
        >
          <el-input
            size="small"
            type="textarea"
            :autosize="{ minRows: 5, maxRows: 10 }"
            v-model="editData.remark"
            clearable
          ></el-input>
        </el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </div>
</template>

<style lang="less" scope>
.ElectricityMonitoring {
  .el-form-item {
    margin-bottom: 0;
  }
  .unit-item {
    margin-bottom: 20px;
    border-bottom: 1px dashed #d5d9e4;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .device-record {
    width: 1200px;
    background-color: #f0f0f0;
  }
  .my-label {
    width: 20%;
  }
  .my-content {
    width: 30%;
  }
  .el-date-editor {
    width: 100% !important;
  }
}
</style>

<script>
import DeviceMenu from "@/components/DeviceMenu.vue";

export default {
  name: "ElectricityMonitoring",
  components: {
    DeviceMenu,
  },
  data() {
    return {
      deviceType: 5, //1:塔机  2：卸料机 3：升降机   4:扬尘   5：用电   6：反光背心  7：安全冒摄像头 8:建大仁科扬尘设备
      currentProject: null,
      deviceName: "",
      serialNumber: "",

      projectList: [],
      deviceList: [],

      pageSize: 10,
      pageNum: 1,
      totals: 0,

      loading: null,

      dialogTableVisible: false,
      currentDeviceName: null,
      currentDeviceId: null,
      currentDevice: null,
      DeviceDateZone: [],
      DeviceDataType: 1, // 1 实时数据， 2 报警记录
      DeviceRecord: [],
      DevicePageSize: 10,
      DevicePageNum: 1,
      DeviceTotals: 0,

      dialogEditVisible: false,

      editData: {
        deviceName: null,
        serialNumber: null,
        pkey: null,
        dsn: null,
        deviceModel: null,
        recordData: null,
        recordNo: null,
        mintedDate: null,
        mintedNumber: null,
        manufacturer: null,
        propertyUnit: null,
        licenseNo: null,
        contactNumber: null,
        installDate: null,
        remark: null,
      },
    };
  },
  computed: {
    startTime: function () {
      if (!this.DeviceDateZone) {
        return null;
      }
      if (this.DeviceDateZone.length == 2) {
        return this.DeviceDateZone[0];
      }
    },
    endTime: function () {
      if (!this.DeviceDateZone) {
        return null;
      }
      if (this.DeviceDateZone.length == 2) {
        return this.DeviceDateZone[1];
      }
    },
  },
  mounted() {
    this.getProjectList(); // 加载项目列表数据
  },
  watch: {
    currentProject: function (val) {
      this.deviceName = "";
      this.serialNumber = "";
      this.getDeviceList(); // 获取设备列表
    },
    DeviceDataType: function () {
      this.DevicePageNum = 1;
      this.searchDeviceData();
    },
  },
  methods: {
    // 加载项目列表数据
    getProjectList: function () {
      var that = this;
      that.radio = null;
      that.currentUnit = null;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        proName: "",
        projectSysNo: "",
        proAddress: "",
        pageNum: 1,
        pageSize: 10,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/project/projectSearch",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.projectList = res.data.projectList;
          if (res.data.projectList.length) {
            that.currentProject = res.data.projectList[0].id;
          }
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 获取设备列表数据
    getDeviceList: function () {
      var that = this;
      that.radio = null;
      that.currentUnit = null;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        projectId: that.currentProject,
        deviceName: that.deviceName,
        serialNumber: that.serialNumber,
        deviceType: that.deviceType,
        pageNum: that.pageNum,
        pageSize: that.pageSize,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/particular/queryPage",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.deviceList = res.data.particularList;
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 点击选中当前设备
    setCurrentDevice: function (row, column, event) {
      var that = this;
      console.log("setCurrentDevice =>", row, column, event);
      this.currentDevice = row;
      this.currentDeviceName = row.deviceName;
      this.currentDeviceId = row.id;
      this.dialogTableVisible = true;
      this.searchDeviceData();
    },
    // 关闭设备详情数据窗口
    closeDeviceDialog: function () {
      var that = this;
      this.dialogTableVisible = false;
      this.currentDeviceName = null;
      this.currentDeviceId = null;
      (this.currentDevice = null), (this.DeviceDateZone = []);
      this.DeviceDataType = 1;
      this.DeviceRecord = [];
      this.DevicePageSize = 10;
      this.DevicePageNum = 1;
      this.DeviceTotals = 0;
    },
    // 查询数据记录
    searchDeviceData: function () {
      var that = this;
      that.radio = null;
      that.currentUnit = null;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        equipmentId: that.currentDeviceId,
        startTime: that.startTime,
        endTime: that.endTime,
        pageNum: that.DevicePageNum,
        pageSize: that.DevicePageSize,
      };
      // if (data.startTime == data.endTime) {
      //   data.endTime = null
      // }
      var apiUrl;
      if (this.DeviceDataType == 2) {
        apiUrl = "api/admin/equipmentAlarmLog/getInAlarmformation";
      } else if (this.DeviceDataType == 1) {
        apiUrl = "api/admin/equipmentAlarmLog/getInformation";
      }
      that
        .$axiosAdmin({
          method: "post",
          url: apiUrl,
          data: JSON.stringify(data),
        })
        .then((res) => {
          console.log("searchDeviceData =>", res.data);
          if (that.DeviceDataType == 2) {
            that.DeviceRecord = res.data.alarmRecordList;
          } else if (that.DeviceDataType == 1) {
            that.DeviceRecord = res.data.realTimeList;
          }
          that.DeviceTotals = res.data.totals;
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 关闭编辑窗口
    closeEditDialog: function () {
      this.dialogEditVisible = false;
      this.currentDeviceName = null;
      this.currentDeviceId = null;
      this.currentDevice = null;
      this.editData = {
        deviceName: null,
        serialNumber: null,
        pkey: null,
        dsn: null,
        deviceModel: null,
        recordData: null,
        recordNo: null,
        mintedDate: null,
        mintedNumber: null,
        manufacturer: null,
        propertyUnit: null,
        licenseNo: null,
        contactNumber: null,
        installDate: null,
        remark: null,
      };
    },

    // 编辑设备
    editDevice: function (row) {
      this.currentDevice = row;
      this.currentDeviceName = row.deviceName;
      this.currentDeviceId = row.id;
      this.dialogEditVisible = true;
      this.editData = {
        deviceName: row.deviceName,
        serialNumber: row.serialNumber,
        pkey: row.pkey,
        dsn: row.dsn,
        deviceModel: row.deviceModel,
        recordData: row.recordData,
        recordNo: row.recordNo,
        mintedDate: row.mintedDate,
        mintedNumber: row.mintedNumber,
        manufacturer: row.manufacturer,
        propertyUnit: row.propertyUnit,
        licenseNo: row.licenseNo,
        contactNumber: row.contactNumber,
        installDate: row.installDate,
        remark: row.remark,
      };
    },

    // 保存或添加设备信息
    saveEditData: function () {
      var that = this;
      var data = {
        id: this.currentDeviceId,
        projectId: this.currentProject,
        deviceType: this.deviceType,
        deviceName: this.editData.deviceName,
        serialNumber: this.editData.serialNumber,
        pkey: this.editData.pkey,
        dsn: this.editData.dsn,
        deviceModel: this.editData.deviceModel,
        recordData: this.editData.recordData,
        recordNo: this.editData.recordNo,
        mintedDate: this.editData.mintedDate,
        mintedNumber: this.editData.mintedNumber,
        manufacturer: this.editData.manufacturer,
        propertyUnit: this.editData.propertyUnit,
        licenseNo: this.editData.licenseNo,
        contactNumber: this.editData.contactNumber,
        installDate: this.editData.installDate,
        remark: this.editData.remark,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/particular/saveOrEdit",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.dialogEditVisible = false;
          that.getDeviceList();
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 删除设备
    delDevice: function (row) {
      var that = this;
      this.currentDevice = row;
      this.currentDeviceName = row.deviceName;
      this.currentDeviceId = row.id;
      that
        .$confirm(`此操作将删除 ${row.deviceName}, 是否继续?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          var data = {
            id: row.id,
          };
          that.loading = that.$loading({
            lock: true,
            text: "Loading...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          that
            .$axiosAdmin({
              method: "post",
              url: "api/admin/particular/deleteBy",
              data: JSON.stringify(data),
            })
            .then((res) => {
              that.loading.close();
              console.log("成功=>", res);
              if (res.code == 0) {
                that.$notify({
                  title: "操作成功",
                  message: "已成功删除设备",
                  type: "success",
                });
                that.getDeviceList();
              } else {
                that.$notify({
                  title: "警告",
                  message: "设备删除失败",
                  type: "warning",
                });
              }
            })
            .catch((err) => {
              console.log("失败=>", err);
              that.loading.close();
            });
        })
        .catch(() => {
          that.$notify({
            title: "警告",
            message: "已取消删除，请谨慎操作。",
            type: "warning",
          });
        });
    },
    // 根据 id 删除部门/班组
    delDepartment: function () {
      var that = this;
      if (that.radio == null) {
        that.$alert("请先选择要删除的部门/班组", "提示", { showClose: false });
        return;
      }
      var department = that.departmentList[that.radio];
      that
        .$confirm(
          `此操作将删除 ${department.labourGroupName}, 是否继续?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
        .then(() => {
          var data = {
            id: department.id,
          };
          that.loading = that.$loading({
            lock: true,
            text: "Loading...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          that
            .$axiosAdmin({
              method: "post",
              url: "api/admin/labourgroup/deleteLabourGroup",
              data: JSON.stringify(data),
            })
            .then((res) => {
              that.loading.close();
              console.log("成功=>", res);
              if (res.code == 0) {
                that.$notify({
                  title: "操作成功",
                  message: "已成功删除部门/班组",
                  type: "success",
                });
                that.getDepartmentList();
              } else {
                that.$notify({
                  title: "警告",
                  message: "部门/班组删除失败",
                  type: "warning",
                });
              }
            })
            .catch((err) => {
              console.log("失败=>", err);
              that.loading.close();
            });
        })
        .catch(() => {
          that.$notify({
            title: "警告",
            message: "已取消删除，请谨慎操作。",
            type: "warning",
          });
        });
    },
  },
};
</script>